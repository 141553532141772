@font-face {
  font-family: 'Megrim';
  font-weight: 400;
  src: local('Megrim'), url('../../fonts/Megrim-Regular.ttf') format('truetype')
}

.app-header {
  background-color: rgb(254, 254, 254);
  grid-row: 1;
  position: sticky;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
  transition: box-shadow 250ms ease;
  z-index: 1;
}

.app-header > h1 {
  font-family: 'Megrim', cursive;
  font-size: 1.75em;
  font-weight: 600;
}

.scrolled {
  box-shadow: 0 2px 16px rgba(0, 0, 0, .1);
}

@media only screen and (max-width: 768px) {
  header {
    padding: 0 24px;
  }

  header > h1 {
    margin-right: 24px;
  }
}
