.project-wrapper {
  background-color: white;
  width: 100%;
  max-width: 1440px;
  position: relative;
  display: flex;
  justify-content: center;
}

.pw-odd {
  border-top: solid 1px rgba(0, 0, 0, .15);
  border-bottom: solid 1px rgba(0, 0, 0, .15);
}

.project {
  max-width: 1200px;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 48px;
  padding: 48px;
  display: none;
}

.project.img-loaded {
  display: grid;
}

.proj-img {
  width: 100%;
  /* border: solid 1px rgba(77, 139, 165, .15); */
}

.proj-interface {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.proj-details > h3 {
  margin-bottom: 4px;
  font-size: 1.5em;
  font-weight: 600;
  color: rgb(50, 50, 50);
}

.proj-tech {
  position: relative;
  display: flex;
  align-items: center;
  list-style: none;
  margin-bottom: 24px;
  font-weight: 500;
  color: rgb(128, 128, 128);
}

.proj-tech > img {
  width: 8px;
  margin: 0 8px;
}

.proj-tech > span {
  background-color: rgb(0, 0, 0, .5);
  width: 1px;
  height: 1.1em;
  margin: 0 8px;
}


.proj-desc {
  margin: 0 0 48px 32px;
}

.proj-desc > li {
  margin-bottom: 8px;
}

.proj-actions {
  display: flex;
  align-self: center;
}

.proj-actions > a {
  background-color: rgb(240, 128, 128);
  padding: 8px 20px;
  border-radius: 4px;
  text-decoration: unset;
  font-weight: 600;
  color: white;
  transition: background-color 750ms ease;
}

.proj-actions > a:hover {
  background-color: rgb(235, 158, 158);
}

.proj-actions > a:nth-child(1) {
  margin-right: 24px;
}

@media only screen and (max-width: 1024px) {
  .project {
    grid-template-columns: unset;
    grid-template-rows: max-content max-content;
    column-gap: unset;
    row-gap: 24px;
  }

  .proj-img {
    width: 100%;
  }

  .proj-desc {
    margin-bottom: 32px;
  }
}

@media only screen and (max-width: 768px) {
  .project-wrapper {
    margin: unset;
  }

  .pw-odd {
    background-color: unset;
    border: unset;
  }
  
  .project {
    row-gap: 24px;
    padding: 24px;
  }
}

@media only screen and (max-width: 460px) {
  .project {
    padding: unset;
  }
}
