.banner-msg,
.projects-sub {
  display: flex;
  justify-content: center;
  align-items: center;
 }

.banner-msg {
  background-color: rgb(17, 68, 89);
  padding: 48px 24px;
}

.banner-msg > h2 {
  max-width: 1200px;
  text-align: center;
  font-size: 2.75em;
  font-weight: 300;
  color: white;
}

.banner-msg > h2 > em {
  font-size: 1em;
  font-family: 'Megrim', cursive;
  font-style: unset;
}

.projects-sub {
  background-color: rgb(248, 248, 248);
  display: flex;
  flex-direction: column;
  padding: 24px;
}

.projects-sub > h4 {
  margin-bottom: 4px;
  font-size: 1em;
  font-weight: 600;
  color: rgb(176, 176, 176);
}

.projects-sub > h3 {
  font-size: 2em;
  font-weight: 400;
  color: rgb(17, 68, 89);
}

@media only screen and (max-width: 1024px) {
  .banner-msg > h2 {
    font-size: 2.25em;
  }
}

@media only screen and (max-width: 768px) {
  .banner-msg {
    display: none;
  }

  .projects-sub {
    background-color: rgb(17, 68, 89);
  }

  .projects-sub > h4 {
    color: rgba(255, 255, 255, .45);
  }

  .projects-sub > h3 {
    color: rgba(255, 255, 255, .95)
  }
}
