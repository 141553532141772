.about-footer {
  background-color: rgb(245, 245, 245);
  /* background-color: rgba(240, 168, 168); */
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 24px;
  color: rgb(98, 98, 98);
}

.author-msg {
  margin-bottom: 8px;
  text-align: center;
  color: inherit;
}

.repo-link {
  color: inherit;
}


.repo-link > a {
  text-decoration: unset;
  font-weight: 600;
  color: inherit;
}
