.contact-con {
  background-color: white;
  display: flex;
  align-items: center;
  padding: 48px 0;
}

.contact {
  width: 100%;
  max-width: 1024px;
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  margin: 0 auto;
}

.connection-grid {
  display: grid;
  align-self: center;
  grid-template-columns: max-content;
  grid-template-rows: max-content;
  column-gap: 64px;
  row-gap: 48px;
}

.connection-grid > h2 {
  grid-column: 1;
  grid-row: 1;
  text-align: center;
  font-size: 1.4em;
  font-weight: 400;
  color: rgb(79, 79, 79);
}

.connections {
  height: fit-content;
  display: flex;
}

.connection-sep {
  display: grid;
  grid-template-rows: 1fr max-content 1fr;
  row-gap: 16px;
}

.sep-line {
  background-color: rgba(0, 0, 0, .2);
  width: 1px;
  margin: 0 auto;
}

@media only screen and (max-width: 576px) {
  .contact-con {
    padding: 48px 24px;
  }

  .contact {
    flex-direction: column;
    align-items: center;
  }

  .connection-sep {
    width: 100%;
    grid-template-columns: 1fr max-content 1fr;
    grid-template-rows: unset;
    column-gap: 16px;
    row-gap: unset;
    margin: 48px 0;
  }

  .sep-line {
    width: 100%;
    height: 1px;
    margin: auto 0;
  }
}

@media only screen and (max-width: 480px) {
  .connection-sep {
    margin: 48px 0;
  }

  .connection-grid {
    row-gap: 24px;
  }
}
