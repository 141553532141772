.loader-wrapper {
  background-color: transparent;
  width: 100%;
  height: 100%;
  min-height: 10em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.loader {
  border: solid 4px transparent;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
  z-index: 1;
}

.l-light {
  border-color: white;
  border-top: solid 4x rgb(145, 145, 145);
}

.l-dark {
  border-color: rgb(245, 245, 245);
  border-top: solid 4px rgb(145, 145, 145);
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
