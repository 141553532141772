.project-select {
  background-color: rgb(254, 254, 254);
  width: 100%;
  height: 3.5rem;
  position: sticky;
  bottom: 0;
  display: none;
  padding: 0 24px;
  box-shadow: 0 -2px 16px rgba(0, 0, 0, .1);
}

.project-btns {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 12px;
  align-items: center;
  padding: 4px 24px;
  
}

.project-btns > button {
  background-color: transparent;
  justify-self: center;
  border: unset;
  font-size: 1.2em;
  font-weight: 600;
}

.project-btn {
  width: 28px;
  height: 28px;
  opacity: .2;
  cursor: pointer;
}

.project-btn.current {
  opacity: 1
}

.project-logo {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.project-logo.current {
  opacity: 1;
}

@media only screen and (max-width: 768px) {
  .project-select {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
