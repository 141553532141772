.site-nav {
  height: 100%;
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, max-content);
}

.site-nav.nav-mobile {
  column-gap: 48px;
}

.site-nav > a {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 24px;
  border-bottom: solid 3px transparent;
  text-decoration: unset;
  font-size: 1em;
  font-weight: 400;
  color: rgba(0, 0, 0, .3);
}

.site-nav.nav-mobile > a {
  position: relative;
  padding: unset;
  border: unset;
}

.site-nav.nav-mobile > .projects-link::after {
  background-color: rgba(0, 0, 0, .2);
  width: 1px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: -24px;
  margin: 12px 0px;
  content: "";
}

.site-nav-mobile > .projects-link {
  margin-right: 48px;
}

.site-nav > .nav-selected {
  color: black;
}

.portfolio-icon,
.about-icon {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 16px;
  height: 16px;
  margin-bottom: 4px;
}

.icon-mobile {
  width: 24px;
  height: 24px;
}

.portfolio-icon {
  background-image: url('./assets/portfolio_icon.svg');
}

.about-icon {
  background-image: url('./assets/about_icon.svg');
}

.nav-selected > .portfolio-icon {
  background-image: url('./assets/portfolio_icon_selected.svg');
}

.nav-selected > .about-icon {
  background-image: url('./assets/about_icon_selected.svg');
}
