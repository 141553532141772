.App {
  min-height: 100vh;
  display: grid;
  grid-template-rows: 3.5rem auto;
}

main {
  height: 100%;
  grid-row: 2;
}
