.about {
  background-color: rgb(245, 245, 245);
  height: 100%;
  display: grid;
  grid-template-rows: auto 1fr 8rem;
  padding-bottom: unset;
}

.profile {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 48px;
}

.profile > .profile-img-con,
.profile > .profile-story {
  display: none;
}

.profile.img-loaded > .profile-img-con,
.profile.img-loaded > .profile-story {
  display: block;
}

.profile-img-con {
  width: 256px;
  height: 256px;
  margin-right: 24px;
}

.profile-img-con > img {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.profile-story {
  background-color: white;
  text-align: center;
  padding: 24px 32px;
  border-radius: 16px;
}

.profile-story > h2 {
  margin-bottom: 16px;
  font-size: 1.5em;
  font-weight: 600;
}

.profile-story > h2 > em {
  font-style: normal;
  color: rgb(240, 108, 108);
}

.profile-story > p {
  max-width: 560px;
  line-height: 1.25em;
  text-align: center;
  font-size: 1.2em;
  font-weight: 300;
}

.loading-con {
  background-color: transparent;
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-wheel {
  border: 4px solid rgb(245, 245, 245);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
  z-index: 1;
}

.loading-wheel.light {
  border-color: white;
  border-top: 4px solid rgb(145, 145, 145);
}

.loading-wheel.dark {
  border-color: rgb(245, 245, 245);
  border-top: 4px solid rgb(145, 145, 145);
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@media only screen and (max-width: 1024px) {
  .profile {
    background-color: white;
    border-radius: 16px;
  }

  .profile-img-con {
    width: 25%;
    height: 100%;
    margin-right: unset;
  }

  .profile-img-con > img {
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
    border-radius: unset;
    border-bottom-left-radius: 16px;
    border-top-left-radius: 16px;
  }

  .profile-story {
    width: 75%;
  }
}

@media only screen and (max-width: 768px) {
  .profile {
    background-color: unset;
    flex-direction: column;
    margin: 24px;
    margin-bottom: 48px;
  }

  .profile-img-con {
    width: 256px;
    margin-bottom: 24px;
  }

  .profile-img-con > img {
    border-radius: 50%;
  }

  .profile-story {
    background-color: white;
    width: fit-content;
    padding: 16px 24px;
  }
}

@media only screen and (max-width: 480px) {
  .profile {
    background-color: white;
    position: relative;
    margin: unset;
    border-radius: unset;
  }

  .profile::after {
    background-color: rgba(0, 0, 0, .2);
    content: "";
    height: 2px;
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    margin: 0 24px;
  }

  .profile-img-con {
    width: 100%;
    height: 180px;
    margin-bottom: unset;
  }

  .profile-img-con > img {
    border-radius: unset;
  }

  .profile-story {
    margin: 0 24px;
    padding: 48px 0;
    border-radius: unset;
  }
}
