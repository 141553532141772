.connection,
.connection-mobile {
  background-color: rgb(254, 254, 254);
  width: fit-content;
  justify-self: center;
  align-self: center;
  margin: 0 24px;
  border: solid 1px rgba(0, 0, 0, .4);
}

.connection {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  font-weight: 400;
  text-decoration: unset;
  color: inherit;
  transition: box-shadow 250ms ease;
}

.connection-mobile {
  padding: 16px;
  border-radius: 8px;
}

.connection:hover {
  box-shadow: 0 4px 16px rgba(0, 0, 0, .2);
  font-weight: 600;
}

.connect-img-con {
  width: 64px;
  height: 64px;
  margin: 24px;
}

.connect-img-con > img {
  background-color: white;
  width: 100%;
  object-fit: contain;
}

.connection > h4 {
  width: 90%;
  padding: 12px 0;
  border-top: solid 1px rgba(0, 0, 0, .25);
  text-align: center;
  font-weight: inherit;
}

@media only screen and (max-width: 1024px) {
  .connect-img-con {
    width: 48px;
    height: 48px;
  }
}

@media only screen and (max-width: 768px) {
  .connect-img-con {
    width: 32px;
    height: 32px;
    margin: unset;
  }

  .connection-mobile > h4 {
    display: none;
  }
}
