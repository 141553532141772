.projects {
  /* background-color: rgb(250, 250, 250); */
  height: 100%;
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: max-content auto;
}

.project-grid {
  position: relative;
  grid-row: 2;
  display: grid;
  justify-items: center;
  padding: 0 24px;
}

@media only screen and (max-width: 768px) {
  .project-grid {
    padding: unset;
  }

  .projects {
    grid-template-rows: max-content 1fr 3.5rem;
  }
}

@media only screen and (max-width: 460px) {
  .project-grid {
    padding: 24px;
  }
}
